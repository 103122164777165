import React, { useContext } from 'react'
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import showdown from "showdown"
import ReactMarkdown from 'react-markdown';

import QueryContext from '../components/QueryContext';

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import SignUpSection from '../components/SignUpSection'
import IconCheck from "../components/icons/IconCheck"

export default function VotingRegistration({ data }) {
  const [ query, setQuery ] = useContext(QueryContext);

  // = = = = = = = = 
  // Forestry Data

  const pageData = data.markdownRemark.frontmatter;
  const converter = new showdown.Converter();
  const dateDiff = Math.abs(data.electionsDate.frontmatter.elections_date);

  
  // = = = = = = = = 
  // Events Functions
  // Get Diff Date
  const getDaysDifference = () => {
    const presentDate = new Date();
    const electionDate = new Date(data.electionsDate.frontmatter.elections_date);
    // Plus 1  to include the election date
    const daysDiff = Math.ceil((electionDate - presentDate) / (1000 * 3600 * 24)) + 1;
    return daysDiff;
  }


  // = = = = = = = = 
  // Events Functions

  // Sets query to current input value
  const handleInputChange = e => {
    setQuery(e.target.value);
  }

  return(
    <>
      <Seo title = { pageData.page_title } />

      <Hero 
        headline = { pageData.hero_headline }
        ctas = { pageData.hero_ctas }
        justify = "center"
        size = "big"
        page="voting-registration"
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/voting-registration-hero.jpg" 
          alt="" 
          placeholder="blurred"
          loading="eager"
        />
      </Hero>

      <section id="workers-section" className="business-registration">
        <div className="container">
          <div className="row row--no-padding-mb">

            <div className="col-md-5">
              <StaticImage 
                src="../images/registration-1.png" 
                alt="Candidate portrait"
                placeholder="blurred"
              />
            </div>

            <div className="col-md-6">
              <div className="section-content">
                {/* <p className="registration-date"><span className="registration-date__number">{getDaysDifference()}</span> days left to register to vote</p> */}
                <ReactMarkdown className="section-content__headline" >{pageData.page_sections[0].section_headline}</ReactMarkdown>
                <ReactMarkdown className="section-content__content" >{pageData.page_sections[0].section_content}</ReactMarkdown>
   
                <div className="section-content__ctas">
                  <Link to={pageData.page_sections[0].section_buttons[0].button_url} className="button">{pageData.page_sections[0].section_buttons[0].button_label}<span className="button-arrow">›</span></Link>
                  <Link to={pageData.page_sections[0].section_buttons[1].button_url} className="button">{pageData.page_sections[0].section_buttons[1].button_label}<span className="button-arrow">›</span></Link>
                  <Link to={pageData.page_sections[0].section_buttons[2].button_url} className="button button--white">{pageData.page_sections[0].section_buttons[2].button_label}<span className="button-arrow">›</span></Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="workers-registration">
        <div className="container">
          <div className="row row--no-padding-mb">

            <div className="col-md-6 offset-md-1">
              <div className="section-content">
                
                <div className="section-content__headline">
                  <IconCheck />
                  <ReactMarkdown>{pageData.page_sections[1].section_headline}</ReactMarkdown>
                </div>
                <ReactMarkdown className="section-content__content">{pageData.page_sections[1].section_content}</ReactMarkdown>
             
                
                <div className="section-content__form">
                  <form action="">
                    <input 
                      type="text" 
                      onChange={ (e) => handleInputChange(e) }
                      placeholder="Organisation name" 
                      name="" 
                      id="" 
                    />
                    <Link to="/voter-registration" className="button button--transparent">Search</Link>
                  </form>
                </div>
              </div>
            </div>
            
            <div className="col-md-5">
              <StaticImage 
                src="../images/registration-2.png" 
                alt="Candidate portrait"
                placeholder="blurred"
              />
            </div>

          </div>
        </div>
      </section>

      <section id="residents-section"  className="residents-registration">
        <div className="container">
          <div className="row residents-wrapper row--no-padding-mb">

            <div className="col-md-12 content-wrapper">
              <div className="bg-blue">
                <div className="row">
                  <div className="col-md-6">
                    <div className="section-content">
                      <ReactMarkdown className="section-content__headline">{pageData.page_sections[2].section_headline}</ReactMarkdown>
                      <ReactMarkdown className="section-content__content">{pageData.page_sections[2].section_content}</ReactMarkdown>
                      <div className="section-content__ctas">
                        <Link to={pageData.page_sections[2].section_buttons[0].button_url} className="button">{pageData.page_sections[2].section_buttons[0].button_label}<span className="button-arrow">›</span></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-5 picture-wrapper">
              <StaticImage 
                src="../images/registration-3.png" 
                alt="Candidate portrait"
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="widget-section">
        <div className="container">
          <div className="row row--no-padding-mb">

            <div className="col-md-4">
              <StaticImage 
                src="../images/registration-4.png" 
                alt="Candidate portrait"
                placeholder="blurred"
              />
            </div>

            <div className="col-md-5">
              <div className="section-content">
                <ReactMarkdown className="section-content__headline">{pageData.page_sections[3].section_headline}</ReactMarkdown>
                <ReactMarkdown className="section-content__content">{pageData.page_sections[3].section_content}</ReactMarkdown>
                
                <div className="section-content__ctas">
                  <Link to={pageData.page_sections[3].section_buttons[0].button_url} className="button">{pageData.page_sections[3].section_buttons[0].button_label}<span className="button-arrow">›</span></Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <SignUpSection />
    </>
  )
}

// = = = = = = = = 
// GraphQL
export const query = graphql`
  query RegistrationQuery {
    markdownRemark(frontmatter: {page_title: {eq: "Voting Registration"}}) {
      frontmatter {
        page_title
        hero_headline
        hero_ctas {
          button_label
          button_url
        }
        page_sections {
          section_headline
          section_content
          section_buttons {
            button_label
            button_url
          }
        }
      }
    },

    electionsDate: markdownRemark(frontmatter: {date_id: {eq: "ward-elections"}}) {
      frontmatter {
        elections_date(formatString: "M/D/Y")
      }
    }
  }
`;